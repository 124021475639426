import React, { useCallback, useRef, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { MdAttachMoney } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import pgto, { interestRate, amortizationSac } from '../../utils/pgto';
import CountUp from 'react-countup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Slider from '../../components/SliderComponent';
import StepProgressBar from '../StepProgressBar';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  FormGroup,
  InputGroup,
  SliderGroup,
  RadioGroup,
  Radio,
  Label,
  LabelRadio,
  SimulationSummary,
  SimulationSummaryMobile,
} from './styles';
import { currency, currencyToNumber } from '../../utils/format-currency';

const domainPrice = [36, 144];
const domainSac = [36, 240];

const HomeEquitySimulator: React.FC = (props: any) => {
  const rate = 1.1; //0,85% A.M. + IPCA

  const [update, setUpdate] = useState<ReadonlyArray<number>>([
    props.getState('total_installments', 90),
  ]);
  const [values, setValues] = useState<ReadonlyArray<number>>([
    props.getState('total_installments', 90),
  ]);

  const [loanAmount, setLoanAmount] = useState(
    props.getState('loan_amount', ''),
  );
  const [propertyValue, setPropertyValue] = useState(
    props.getState('property_value', ''),
  );
  const [amortizationTable, setAmortizationTable] = useState(
    props.getState('amortization_table', 'price'),
  );
  const [totalInstallments, setTotalInstallments] = useState(
    props.getState('total_installments', 90),
  );

  const [firstInstallment, setFirstInstallment] = useState(0);
  const [minimumIncome, setMinimumIncome] = useState(0);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  //Valor padrao da tabela de amortização e das parcelas
  useEffect(() => {
    //Product Id
    if (props.getState('product_id') === undefined) {
      props.setState('product_id', 1);
    }

    if (props.getState('total_installments') === undefined) {
      props.setState('total_installments', 90);
    }

    if (props.getState('amortization_table') === undefined) {
      props.setState('amortization_table', 'price');
    }
  }, [props]);

  const onUpdate = (u: ReadonlyArray<number>) => {
    setUpdate(u);
    props.setState('total_installments', u);
    console.log(update);
  };

  const onChange = (v: ReadonlyArray<number>) => {
    props.setState('total_installments', v[0]);
    setTotalInstallments(v[0]);
    setValues(v);
  };

  const handleChangeRadio = (amortization: string) => {
    props.setState('amortization_table', amortization);
    setAmortizationTable(amortization);
  };

  const next = async () => {
    try {
      const data = {
        loan_amount: currencyToNumber(loanAmount),
        property_value: propertyValue,
        total_installments: totalInstallments,
        amortization_table: amortizationTable,
      };

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        loan_amount: Yup.number()
          .min(50000, 'Valor mínimo de R$ 50000')
          .required('valor do empréstimo é obrigatório'),
        property_value: Yup.string().required('valor do imóvel é obrigatório'),
        total_installments: Yup.number().required(
          'Quantidade de parcelas é obrigatório',
        ),
        amortizationTable: Yup.number().notRequired(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      props.next();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        addToast({
          type: 'error',
          title: 'Erro ao prosseguir',
          description: 'Por favor preencha todos os campos.',
        });

        return;
      }
    }
  };

  const handleChangeFieldForm = useCallback(async () => {
    const numberFormat = currencyToNumber(loanAmount);
    if (numberFormat < 50000) return;

    if (amortizationTable === 'price') {
      setFirstInstallment(pgto(numberFormat, totalInstallments, rate));
      setMinimumIncome((pgto(numberFormat, totalInstallments, rate) * 1) / 0.35);
    } else {
      const juros = interestRate(numberFormat, rate);
      const amortizationCurrent = amortizationSac(
        numberFormat,
        totalInstallments,
      );

      setFirstInstallment(amortizationCurrent + juros);
      setMinimumIncome((amortizationCurrent + juros * 1) / 0.35);
    }
  }, [
    setFirstInstallment,
    amortizationTable,
    totalInstallments,
    loanAmount,
    rate,
  ]);

  useEffect(() => {
    handleChangeFieldForm();
  }, [
    props,
    loanAmount,
    propertyValue,
    amortizationTable,
    totalInstallments,
    handleChangeFieldForm,
  ]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <div>
            <StepProgressBar
              steps={[
                'Simulação',
                'Meus Dados',
                'Dados do Imóvel',
                'Solicitação',
              ]}
              currentStep={0}
            />
          </div>

          <Form ref={formRef} onSubmit={() => {}}>
            <h1>Meu Empréstimo</h1>

            <FormGroup>
              <InputGroup>
                <Label>Valor do empréstimo</Label>
                <Input
                  name="loan_amount"
                  icon={MdAttachMoney}
                  type="text"
                  placeholder="R$ 0,00"
                  value={loanAmount}
                  onChange={e => {
                    currency(e);
                    props.setState('loan_amount', e.target.value);
                    setLoanAmount(e.target.value);
                  }}
                />
              </InputGroup>

              <InputGroup>
                <Label>Valor do Imóvel</Label>
                <Input
                  name="property_value"
                  icon={MdAttachMoney}
                  type="text"
                  placeholder="R$ 0,00"
                  value={propertyValue}
                  onChange={e => {
                    currency(e);
                    setPropertyValue(e.target.value);
                    props.setState('property_value', e.target.value);
                  }}
                />
              </InputGroup>
            </FormGroup>

            <SliderGroup>
              <Label>Em quantas parcelas?</Label>
              {amortizationTable === 'price' ? (
                <Slider
                  domain={domainPrice}
                  values={values}
                  onChange={onChange}
                  onUpdate={onUpdate}
                />
              ) : (
                <Slider
                  domain={domainSac}
                  values={values}
                  onChange={onChange}
                  onUpdate={onUpdate}
                />
              )}
            </SliderGroup>

            <RadioGroup>
              <Radio>
                <input
                  type="radio"
                  value="price"
                  checked={amortizationTable === 'price' ? true : false}
                  onChange={e => handleChangeRadio(e.target.value)}
                />
                <LabelRadio>PRICE</LabelRadio>
              </Radio>
              <Radio>
                <input
                  type="radio"
                  value="sac"
                  checked={amortizationTable === 'sac' ? true : false}
                  onChange={e => handleChangeRadio(e.target.value)}
                />
                <LabelRadio>SAC</LabelRadio>
              </Radio>
            </RadioGroup>

            {isMobile && (
              <SimulationSummaryMobile>
                <h4>Valor da primeira parcela</h4>
                <span>
                  <CountUp
                    end={firstInstallment}
                    duration={2}
                    separator="."
                    decimals={2}
                    decimal=","
                    prefix="R$ "
                  />
                </span>
                <p>
                  Renda necessária:{' '}
                  {minimumIncome.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}{' '}
                  <br />
                  Taxa de Juros: 0,85% A.M + IPCA <br />
                  Sistema de Amortização: Tabela{' '}
                  {amortizationTable.toLocaleUpperCase()} <br />
                  Parcelas: {totalInstallments} <br />
                </p>
                <p className="small">
                  * A taxa de juros varia a partir de 0.85% ao mês, <br />
                  dependendo da análise de crédito e do perfil de cada cliente.{' '}
                  <br />
                  A parcela apresentada já inclui os custos <br />
                  aproximados com avaliação do imóvel, seguros <br />e custos
                  cartoriais.
                </p>
              </SimulationSummaryMobile>
            )}

            <Button onClick={next}>Solicitar empréstimo</Button>
          </Form>
        </AnimationContainer>
      </Content>

      <Background>
        <SimulationSummary>
          <h4>Valor da primeira parcela</h4>
          <span>
            <CountUp
              end={firstInstallment}
              duration={2}
              separator="."
              decimals={2}
              decimal=","
              prefix="R$ "
            />
          </span>
          <p>
            Renda necessária:{' '}
            {minimumIncome.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}{' '}
            <br />
            Taxa de Juros: 0,85% A.M + IPCA <br />
            Sistema de Amortização: Tabela{' '}
            {amortizationTable.toLocaleUpperCase()} <br />
            Parcelas: {totalInstallments} <br />
          </p>
          <p className="small">
            * A taxa de juros varia a partir de 0.85% ao mês, <br />
            dependendo da análise de crédito e do perfil de cada cliente. <br />
            A parcela apresentada já inclui os custos <br />
            aproximados com avaliação do imóvel, seguros <br />e custos
            cartoriais.
          </p>
        </SimulationSummary>
      </Background>
    </Container>
  );
};

export default HomeEquitySimulator;
