import React from 'react';
import { Slider, Handles, Rail, Tracks, Ticks } from 'react-compound-slider'
import { SliderRail, Handle, Track, Tick, } from '../SliderContainer';
import { Container } from './styles';

const sliderStyle = {
  position: 'relative' as 'relative',
  width: '100%',
  touchAction: 'none',
};

interface SliderProps {
  domain: any;
  values: ReadonlyArray<number>;
  onUpdate?: (v: ReadonlyArray<number>) => void;
  onChange?: (v: ReadonlyArray<number>) => void;
}

const SliderComponent: React.FC<SliderProps> = ({ domain, values, onUpdate, onChange }) => {

  return (
    <Container>
      <Slider
        mode={1}
        step={1}
        domain={domain}
        rootStyle={sliderStyle}
        onUpdate={onUpdate}
        onChange={onChange}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={10}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </Container>
  );
}

export default SliderComponent;
