import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import menBackground from '../../assets/men.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 800px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 40px 0;

    @media (min-width: 1024px) {
      width: 500px;
    }

    /* text-align: center; */

    h1 {
      margin-bottom: 24px;
    }

    > button {
      @media (min-width: 1024px) {
        width: 300px;
      }
    }
  }

  > a {
    color: #071860;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${menBackground}) no-repeat center;
  background-size: cover;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto 5px;
`;

export const SliderGroup = styled.div`
   display: flex;
  flex-direction: column;

  margin: 50px auto;
`

export const Label = styled.span`
  font-size: 14px;
`;

export const SimulationSummary = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;

    width: 100%;

    position: fixed;
    top: 40%;
    bottom: 0;
    z-index: 2;

    > h4 {
      font-size: 20px;
      color: var(--color-tertiary);
    }

    > span {
      font-size: 40px;
      color: var(--color-tertiary);
      font-weight: bold;
    }

    > p {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
    }

    .small {
      font-size: 8px;
    }
  }
`;

export const SimulationSummaryMobile = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;

    width: 100%;

    background-color: rgb(0, 0, 0, 0.5);

    > h4 {
      font-size: 20px;
      color: var(--color-tertiary);
    }

    > span {
      font-size: 40px;
      color: var(--color-tertiary);
      font-weight: bold;
    }

    > p {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
    }

    .small {
      font-size: 8px;
    }

  @media (min-width: 1024px) {
    display: none;
  }
`;
