import React from 'react';

import { Container } from './styles'

interface StepProgressBarProps {
  currentStep: number;
  steps: string[];
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({ currentStep, steps }) => {

  return <Container>
    <ul className="progressbar">
      {
        steps.map((item, index) =>
          <li key={index} className={index <= currentStep ? 'active' : ''}>
            {item}
          </li>
        )
      }
    </ul>
  </Container>;
}

export default StepProgressBar;
