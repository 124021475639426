import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Cards,
  Card,
  Header,
  Body,
  Footer
} from './styles';

const Welcome: React.FC = () => {
  return (
    <Container>
      <h2>
        Como funciona a plataforma de crédito online da Finmarc, <br />
        parceira dentro do canal MPME do BNDES.
      </h2>

      <p>Solicitação, análise e liberação</p>

      <Cards>
        <Link to="/simulador" className="linkCard">
          <Card>
            <Header>
              <h4>1. Solicitação</h4>
            </Header>
            <Body>
              <p>
                Para cada cliente, uma proposta personalizada. Já fez a sua
                simulação?
              </p>
            </Body>
          </Card>
        </Link>

        <Link to="/bndes" className="linkCard">
          <Card className="clear">
            <Header>
              <h4>2. Análise</h4>
            </Header>
            <Body>
              <p>A taxa de juros varia dependendo do perfil de cada cliente.</p>
            </Body>
          </Card>
        </Link>

        <Link to="/bndes" className="linkCard">
          <Card>
            <Header>
              <h4>3. Liberação</h4>
            </Header>
            <Body>
              <p>Receba seu crédito em sua conta</p>
            </Body>
          </Card>
        </Link>
      </Cards>

      <Footer>
        <Link to="/simulador">Solicitar empréstimo</Link>
      </Footer>
    </Container>
  );
}

export default Welcome;
