import axios from 'axios';

const url = 'https://servicetrello.finmarc.com.br';
// const url = 'http://localhost:3330';

export const apiServiceTrello = axios.create({
  baseURL: url,
});

export default apiServiceTrello;


