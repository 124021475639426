import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signUpBackground from '../../assets/finmarc_bndes.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  width: 100%;
  max-width: 800px;

`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  animation: ${appearFromRight} 1s;

  > h2 {
    font-size: 28px;
    line-height: 50px;

    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }

  > h4 {
    font-weight: 900;
  }

  > button {
    border: none;
    background-color: transparent;
    color: #ffffff;
    display: block;
    margin: 10px 0;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 16px;
      border-radius: 8px;
      border: 2px solid;
      padding: 5px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signUpBackground}) no-repeat center;
  background-size: cover;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  @media (min-width: 1024px) {
    flex-direction: row;

  }

  .linkCard {
    text-decoration: none;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
