import React from 'react';
import {
  Container,
  Content,
  Contato
} from './styles';

const Success: React.FC = () => {

  return (
    <Container>
      <h1>
        Solicitação Recebida!!
      </h1>

      <p>
        Se você já preencheu algumas informações no site do BNDES, <br />
        fique tranquilo que já recebemos sua solicitação! Nossos <br />
        consultores entrarão em contato o mais breve possível. <br />
      </p>

      <Content>
        <Contato>
          <h4>
            Em caso de dúvidas entre em contato
          </h4>
          <p>
            contato@finmarc.com.br
          </p>
          <p>
            Telefone:
            +55 61 3037-9290
          </p>
          <p>
            www.finmarc.com.br
          </p>
        </Contato>
      </Content>

      <a className="especiaista"
        rel="noopener noreferrer"
        target="_blank" href="https://api.whatsapp.com/send?phone=5561994368033&text=Ol%C3%A1..." >
        Converse com
        um especialista
      </a>
    </Container >
  )
}

export default Success;
