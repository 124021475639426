import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../services/api';
import parceriaBndes from '../../assets/parceriaBndes.png';

import Welcome from './Welcome';
import Success from './Success';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Header
} from './styles';

const Bndes: React.FC = () => {

  const propostaId = useQuery().get('propostaId');
  const history = useHistory();

  const back = () => {
    history.push('/');
  }

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <Header>
            <img src={parceriaBndes} alt="parceria_bndes" width="300px" />
          </Header>

          <button onClick={back}>
            <FiChevronLeft />
            Login
          </button>

          {
            propostaId ? <Success /> : <Welcome />
          }

        </AnimationContainer>
      </Content>
    </Container >
  );
};

export default Bndes;
