import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import StepProgressBar from '../StepProgressBar';
import { MdAttachMoney } from 'react-icons/md';
import { FiHome } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';

import Input from '../Input';
import Button from '../Button';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  FormGroup,
  InputGroup,
  Label,
} from './styles';
import InputMask from '../InputMask';

interface PropertyFormData {
  cep: string;
  neighborhood: string;
  complement: string;
  price: string;
  property_type: string;
}

const PropertyForm: React.FC = (props: any) => {

  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: PropertyFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cep: Yup.string().required('CEP é obrigatório'),
          neighborhood: Yup.string().required('Endereço obrigatório'),
          complement: Yup.string().required('Complemento obrigatório'),
          price: Yup.string().required('Valor do imóvel obrigatório'),
          property_type: Yup.string().required('Tipo do imóvel e obrigatório')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        props.next()

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro ao prosseguir',
            description: 'Por favor preencha todos os campos.',
          });

          return;
        }
      }
    },
    [props, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <div>
            <StepProgressBar
              steps={['Simulação', 'Meus Dados', 'Dados do Imóvel', 'Solicitação']}
              currentStep={2} />
          </div>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Dados do imóvel</h1>

            <InputGroup>
              <Label>
                CEP
              </Label>
              <InputMask
                name="cep"
                mask='99.999-999'
                icon={FiHome}
                type="text"
                placeholder="CEP"
                value={props.getState('cep', '')}
                onChange={(e) => {
                  props.setState('cep', e.target.value)
                }}
              />
            </InputGroup>

            <InputGroup>
              <Label>
                Endereço
                </Label>
              <Input
                name="neighborhood"
                icon={FiHome}
                type="text"
                placeholder="Endereço"
                value={props.getState('neighborhood', '')}
                onChange={(e) => {
                  props.setState('neighborhood', e.target.value)
                }}
              />
            </InputGroup>

            <InputGroup>
              <Label>
                Complemento
              </Label>
              <Input
                name="complement"
                icon={FiHome}
                type="text"
                placeholder="complemento"
                value={props.getState('complement', '')}
                onChange={(e) => {
                  props.setState('complement', e.target.value)
                }}
              />
            </InputGroup>

            <FormGroup>
              <InputGroup>
                <Label>
                  Valor do Imóvel
                </Label>
                <Input
                  name="price"
                  icon={MdAttachMoney}
                  type="text"
                  placeholder="0,00"
                  value={props.getState('price', props.getState('property_value', ''))}
                  onChange={(e) => {
                    props.setState('price', e.target.value)
                  }}
                />
              </InputGroup>

              <InputGroup>
                <Label>
                  Tipo do imóvel
                </Label>
                <Input
                  name="property_type"
                  icon={FiHome}
                  type="text"
                  placeholder="ex: apartamento"
                  value={props.getState('property_type', '')}
                  onChange={(e) => {
                    props.setState('property_type', e.target.value)
                  }}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <button className="back" onClick={props.prev} >
                <FiChevronLeft />
              Voltar
            </button>
              <Button type="submit"> Avançar </Button>
            </FormGroup>
          </Form>

        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
}

export default PropertyForm;
