import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import menBackground from '../../assets/men.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 800px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 40px 0;
    /* width: 340px; */

    h1 {
      margin-bottom: 24px;
    }

    > button {
      @media (min-width: 1024px) {
        width: 300px;
      }
    }
  }

  > a {
    color: #071860;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${menBackground}) no-repeat center;
  background-size: cover;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto 5px;
`;

export const SliderGroup = styled.div`
   display: flex;
  flex-direction: column;

  margin: 50px auto;
`

export const RadioGroup = styled.div`
   display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin: 50px auto;
`;

export const Radio = styled.div`
   display: flex;
  flex-direction: row;
  align-items: center;

  margin: 10px;

  > input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;

  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #e7e6e7;
  background-color: #e7e6e7;
  border-radius: 50%;

  &:hover {
      cursor: pointer;
    }
  }

  /* appearance for checked radiobutton */
  > input[type="radio"]:checked {
    background-color: #071860;
  }
`;

export const Label = styled.span`
  font-size: 14px;
`;

export const LabelRadio = styled.span`
  font-size: 14px;
  margin-left: 10px;

  font-weight: bold;
`;

export const SimulationSummary = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;

    width: 100%;

    position: fixed;
    top: 40%;
    bottom: 0;
    z-index: 2;

    > h4 {
      font-size: 20px;
      color: var(--color-tertiary);
    }

    > span {
      font-size: 40px;
      color: var(--color-tertiary);
      font-weight: bold;
    }

    > p {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
    }

    .small {
      font-size: 8px;
    }
  }
`;

export const SimulationSummaryMobile = styled.div`

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;

    width: 100%;

    background-color: rgb(0, 0, 0, 0.5);

    > h4 {
      font-size: 20px;
      color: var(--color-tertiary);
    }

    > span {
      font-size: 40px;
      color: var(--color-tertiary);
      font-weight: bold;
    }

    > p {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
    }

    .small {
      font-size: 8px;
    }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const LinkPDF = styled.div`
  > a {
    color: #ffff00;
    display: block;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#ffff00')};
    }
  }
`;
