import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Cards,
  Card,
  Header,
  Body
} from './styles';

const Simulator: React.FC = () => {

  const history = useHistory();

  const back = () => {
    history.goBack();
  }

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <button onClick={back}>
            <FiChevronLeft />
            Voltar
          </button>

          <h2>Solução dos seus problemas financeiros</h2>

          <p>
            Selecione um dos produtos abaixo para simular e conhecer as
            condições
          </p>
          <p>Parceira dentro do canal MPME do BNDES.</p>

          <Cards>
            <Link to="/simulador/homeequity" className="linkCard">
              <Card>
                <Header>
                  <h4>Empréstimo com garantia</h4>
                </Header>
                <Body>
                  <p>
                    Crédito fácil e rápido dando como garantia seu imóvel ou
                    veículo. Melhor taxa de juros e prazo de pagamento.
                  </p>
                </Body>
              </Card>
            </Link>

            {/* <Link to="/simulador/consignado" className="linkCard" >
              <Card className="clear">
                <Header>
                  <h4>
                    Empréstimo Consignado
              </h4>
                </Header>
                <Body>
                  <p>
                    crédito para servidores públicos, aposentados e pensionistas.
                    Descontado direto na folha de pagamento.
                </p>
                </Body>
              </Card>
            </Link> */}

            <Link to="/simulador/capital-de-giro" className="linkCard">
              <Card>
                <Header>
                  <h4>Empréstimo empresarial</h4>
                </Header>
                <Body>
                  <p>
                    Crédito para Capital de Giro e para você investir no seu
                    negócio
                  </p>
                </Body>
              </Card>
            </Link>

            {/* <Link to="/simulador/emprestimo-pessoal" className="linkCard" >
              <Card>
                <Header>
                  <h4>
                    Empréstimo Pessoal
              </h4>
                </Header>
                <Body>
                  <p>
                    Crédito rápido e fácil, 100% on-line. Dependendo da análise do cliente.
                 </p>
                </Body>
              </Card>
            </Link> */}
          </Cards>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default Simulator;
