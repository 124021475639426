const pgto = (
  loanAmount: number,
  totalInstallments: number,
  rate: number) => {

  let e = 1;
  let cont = 1;
  rate = rate / 100;

  for (let k = 1; k <= totalInstallments; k++) {
    cont = cont * (1 + rate);
    e = e + cont;
  }

  e = e - cont;
  loanAmount = loanAmount * cont;

  return loanAmount / e;
}

export const interestRate = (
  loanAmount: number,
  rate: number
) => loanAmount * (rate / 100)

export const amortization = (
  loanAmountCurrent: number,
  interestRateCurrent: number
) => (loanAmountCurrent - interestRateCurrent)

export const amortizationSac = (
  loanAmount: number,
  totalInstallments: number
) => loanAmount / totalInstallments

export const outstandingBalance = (
  amortizationCurrent: number,
  loanAmountCurrent: number
) => loanAmountCurrent - amortizationCurrent

export const allInstallments = (loanAmount: number, totalInstallments: number, rate = 1.1, amortizationTable = 'price'): any[] | undefined => {

  let installments: any[] = []

  let juros = interestRate(loanAmount, rate)
  let parcela = 0
  let amortization = 0

  if (amortizationTable === 'price') {
    parcela = pgto(loanAmount, totalInstallments, rate)
    amortization = parcela - juros
  } else {
    amortization = amortizationSac(loanAmount, totalInstallments)
    parcela = amortization + juros
  }

  let saldoDevedor = loanAmount - amortization
  // minimumIncome = ((pgto(loanAmount, totalInstallments, rate) * 1) / 0.35)

  installments.push({
    totalInstallments,
    rate: juros.toFixed(1),
    amortization: amortization.toFixed(1),
    parcela: parcela.toFixed(1),
    saldo: saldoDevedor.toFixed(1)
  })

  if (totalInstallments >= 1)
    return installments.concat(allInstallments(saldoDevedor, totalInstallments - 1, rate))

}

export default pgto;
