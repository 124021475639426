import React from 'react';
import { Steps, Step } from "react-step-builder";

import EmprestimoPessoalSimulator from '../../components/EmprestimoPessoalSimulator';

import CustomerForm from '../../components/CustomerForm';
import CompletedSteps from '../../components/CompletedSteps';

const EmprestimoPessoalSteps: React.FC = () => {

  const config: any = {
    navigation: {
      location: "after" // or before
    }
  };

  const onHandleChange: any = (order: any) => {
    console.log(order)
  }

  return (
    <>
      <Steps config={config}>
        <Step component={EmprestimoPessoalSimulator} />
        <Step component={CustomerForm} />
        <Step component={CompletedSteps} beforeStepChange={onHandleChange(1)} />
      </Steps>
    </>
  );
};

export default EmprestimoPessoalSteps;
