import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import homeBackground from '../../assets/home.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const HeadPixel = styled.head``

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 800px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;

  animation: ${appearFromLeft} 1s;

  > button {
      @media (min-width: 1024px) {
        width: 300px;
      }
  }

  > a {
    color: #071860;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${homeBackground}) no-repeat center;
  background-size: cover;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .back {
    color: #ffffff;
    display: block;
    margin: 24px;
    text-decoration: none;
    transition: color 0.2s;
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      border-radius: 8px;
      border: 1px solid;
      padding: 5px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin: 5px;
`;

export const SliderGroup = styled.div`
   display: flex;
  flex-direction: column;

  margin: 50px auto;
`

export const RadioGroup = styled.div`
   display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin: 50px auto;
`;

export const Radio = styled.div`
   display: flex;
  flex-direction: row;
  align-items: center;

  margin: 10px;

  > input[type=radio] {
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);

    &:hover {
      cursor: pointer;
    }
  }

`;

export const Label = styled.span`
  font-size: 14px;
`;

export const LabelRadio = styled.span`
  font-size: 14px;
  margin-left: 10px;

  font-weight: bold;
`;

export const Thankmessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 20px auto;

  > p {
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .back {
    color: #ffffff;
    display: block;
    margin: 24px;
    text-decoration: none;
    transition: color 0.2s;
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      border-radius: 8px;
      border: 1px solid;
      padding: 5px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }

`;

export const SolicitationData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 30px auto;
  max-width: 500px;

  > h3 {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
  }

  > ul {
    list-style-type: none;

    li {
      font-size: 14px;
    }

  }
`;

export const Info = styled.span`
  color: #071860;
`;
