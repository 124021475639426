import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
`;

export const Context = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin: 20px;

  @media (min-width: 1024px) {
    margin-left: 80px;
  }

`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  @media (min-width: 1024px) {
    flex-direction: row;

  }

  .linkCard {
    text-decoration: none;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  background-color: var(--color-tertiary);
  border-radius: 8px;
  margin: 5px;

  width: 100%;

  a {
    text-decoration: none;
  }

  &.clear {
    background-color: none;
    border: none;
  }

  @media (min-width: 1024px) {
    width: 180px;
    min-height: 180px;
  }

  &:hover {
    background-color: ${shade(0.2, '#071860')};
    cursor: pointer;

    h4 {
      color: #ffffff;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 4px;

  > h4 {
    color: #071860;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  > a {
    color: #fff;
    background-color: #071860;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;

    padding: 10px;
    border-radius: 20px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: ${shade(0.2, '#071860')};
    }
  }
`;
