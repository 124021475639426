import React from 'react';
import { FiChevronsLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Thankmessage,
} from './styles';

const FinsihSuccess: React.FC = () => {

  return (
    <div>
      <Container>
        <Content>
          <AnimationContainer>
            <Thankmessage>
              <p>
                Sua simulação foi enviada para análise com sucesso <br />e
                nossos consultores entrarão em contato!{' '}
              </p>

              <Link to="/simulador" className="back">
                <FiChevronsLeft />
                Voltar
              </Link>
            </Thankmessage>
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
    </div>
  );
};

export default FinsihSuccess;
