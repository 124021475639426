import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Simulator from '../pages/Simulator';

import HomeEquitySteps from '../pages/HomeEquitySteps';
import ConsignadoSteps from '../pages/ConsignadoSteps';
import CapitalGiroSteps from '../pages/CapitalGiroSteps';

import EmprestimoPessoalSteps from '../pages/EmprestimoPessoalSteps';
import Bndes from '../pages/Bndes';
import FinsihSuccess from '../components/CompletedSteps/sucess';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />

      <Route path="/simulador" exact component={Simulator} />

      <Route path="/simulador/homeequity" exact component={HomeEquitySteps} />
      <Route path="/simulador/consignado" exact component={ConsignadoSteps} />
      <Route path="/simulador/capital-de-giro" exact component={CapitalGiroSteps} />
      <Route path="/simulador/confirmation" exact component={FinsihSuccess} />
      <Route path="/simulador/emprestimo-pessoal" exact component={EmprestimoPessoalSteps} />

      <Route path="/bndes" exact component={Bndes} />

      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
    </Switch>
  );
};

export default Routes;
