import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #ffffff;
  padding: 8px;
  width: 100%;
  margin: 8px auto;

`;
