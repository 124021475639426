import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  h1 {
    color: #ffff00;
    margin: 30px;
  }

  a {

    color: var(--color-tertiary);
    background-color: #071860;
    padding: 20px;
    border-radius: 50px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 20px 0;

  @media (min-width: 1024px) {
    flex-direction: row;
    }
`;

export const Company = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

export const Contato = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;

  h4 {
    margin-bottom: 20px
  }
`;
