import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    width: 100%;
    margin: 40px auto;

    @media(min-width: 1024px){
      width: 600px;
    }

  .progressbar {
      counter-reset: step;
  }

  .progressbar li {
      list-style-type: none;
      width: 25%;
      float: left;
      font-size: 12px;
      position: relative;
      text-align: center;
      color: rgb(211, 211, 211, 0.5);
  }

  .progressbar li:before {
      width: 30px;
      height: 30px;
      content: counter(step);
      counter-increment: step;
      line-height: 30px;
      border: 2px solid rgb(211, 211, 211, 0.5);
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: #0965cb;
  }

  .progressbar li:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: rgb(211, 211, 211, 0.5);
      top: 15px;
      left: -50%;
      z-index: -1;
  }

  .progressbar li:first-child:after {
      content: none;
  }

  .progressbar li.active {
      color: #ffffff;
      font-weight: bold;
  }

  .progressbar li.active:before {
      border-color: #ffffff; //00FF00
  }

  .progressbar li.active + li:after {
      background-color: #ffffff;
  }

`;
