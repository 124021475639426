import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import signInBackground from '../../assets/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 800px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${appearFromLeft} 1s;

  form {
    margin: 40px 0;
    /* width: 340px; */

    h1 {
      margin-bottom: 10px;
    }

    > button {
      @media (min-width: 1024px) {
        width: 300px;
      }
    }
  }

`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .back {
    color: #ffffff;
    display: block;
    margin: 24px;
    text-decoration: none;
    transition: color 0.2s;
    background-color: transparent;
    border: none;

    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 10px;
      border-radius: 8px;
      border: 1px solid;
      padding: 5px;
    }

    &:hover {
      color: ${shade(0.2, '#071860')};
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin: 5px;
`;

export const RadioGroup = styled.div`
   display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin: 10px auto;
`;

export const Radio = styled.div`
   display: flex;
  flex-direction: row;
  align-items: center;

  margin: 10px;

  > input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;

  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #e7e6e7;
  background-color: #e7e6e7;
  border-radius: 50%;

  &:hover {
      cursor: pointer;
    }
  }

  /* appearance for checked radiobutton */
  > input[type="radio"]:checked {
    background-color: #071860;
  }

`;

export const Label = styled.span`
  font-size: 14px;
`;

export const LabelRadio = styled.span`
  font-size: 14px;
  margin-left: 10px;

  font-weight: bold;
`;
