import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import StepProgressBar from '../StepProgressBar';
import { FiPhone, FiMail } from 'react-icons/fi';
import { FaRegIdCard } from 'react-icons/fa';
import { FiChevronLeft } from 'react-icons/fi';

import Input from '../Input';
import Button from '../Button';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  FormGroup,
  InputGroup,
  RadioGroup,
  Radio,
  Label,
  LabelRadio,
} from './styles';
import InputMask from '../InputMask';

interface CustomerFormData {
  name: string;
  email: string;
  document: string;
  phone: string;
  birth_date?: string;
}

const CustomerForm: React.FC = (props: any) => {

  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const stepsArray = [
    'Simulação',
    'Meus Dados',
    'Solicitação']

  //step homeequity
  if (props.state.product_id === 1) {
    stepsArray.splice(2, 0, 'Dados do imóvel');
  }

  const handleSubmit = useCallback(
    async (data: CustomerFormData) => {
      console.log(data);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail obrigatório'),
          document: Yup.string().required('Documento obrigatória'),
          phone: Yup.string().required('Telefone obrigatório'),
          birth_date: Yup.string().required('Data de nasc. obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        props.next()

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro ao prosseguir',
            description: 'Por favor preencha todos os campos.',
          });

          return;
        }
      }
    },
    [props, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <div>
            <StepProgressBar steps={stepsArray} currentStep={1} />
          </div>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Meus Dados</h1>

            <InputGroup>
              <Label>Nome Completo</Label>
              <Input
                name="name"
                icon={FaRegIdCard}
                type="text"
                placeholder="Nome"
                value={props.getState('name', '')}
                onChange={e => {
                  props.setState('name', e.target.value);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Label>E-mail</Label>
              <Input
                name="email"
                icon={FiMail}
                type="text"
                placeholder="e-mail"
                value={props.getState('email', '')}
                onChange={e => {
                  props.setState('email', e.target.value);
                }}
              />
            </InputGroup>

            <InputGroup>
              <Label>CNPJ</Label>
              <InputMask
                name="document"
                icon={FaRegIdCard}
                mask={'99.999.999/9999-99'}
                type="text"
                value={props.getState('document', '')}
                onChange={e => {
                  props.setState('document', e.target.value);
                }}
              />
            </InputGroup>

            <FormGroup>
              <InputGroup>
                <Label>Data Nasc.</Label>
                <InputMask
                  name="birth_date"
                  mask='99/99/9999'
                  icon={FaRegIdCard}
                  type="text"
                  placeholder="dd/mm/yyyy"
                  value={props.getState('birth_date', '')}
                  onChange={e => {
                    props.setState('birth_date', e.target.value);
                  }}
                />
              </InputGroup>

              <InputGroup>
                <Label>Celular / Whatsapp</Label>
                <InputMask
                  name="phone"
                  mask="(99) 99999-9999"
                  icon={FiPhone}
                  type="text"
                  placeholder="telefone"
                  value={props.getState('phone', '')}
                  onChange={e => {
                    props.setState('phone', e.target.value);
                  }}
                />
              </InputGroup>
            </FormGroup>

            <RadioGroup>
              <Radio>
                <input type="radio" value="true" />
                <LabelRadio>
                  Gostaria de receber contato via whatsapp?
                </LabelRadio>
              </Radio>
            </RadioGroup>

            <FormGroup>
              <button className="back" onClick={props.prev}>
                <FiChevronLeft />
                Voltar
              </button>
              <Button type="submit"> Avançar </Button>
            </FormGroup>
          </Form>
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
}

export default CustomerForm;
