import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import StepProgressBar from '../StepProgressBar';
import { FiChevronLeft } from 'react-icons/fi';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import apiServiceTrello from '../../services/trello';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  SolicitationData,
  FormGroup,
  Info,
} from './styles';

const CompletedSteps: React.FC = (props: any) => {
  const { addToast } = useToast();
  const history = useHistory();
  const stepsArray = ['Simulação', 'Meus Dados', 'Solicitação'];

  //step homeequity
  if (props.state.product_id === 1) {
    stepsArray.splice(2, 0, 'Dados do imóvel');
  }

  const handleSubmit = useCallback(async () => {
    try {
      const data = {
        product_id: props.state.product_id,
        loan_amount: props.state.loan_amount,
        total_installments: props.state.total_installments,
        amortization_table: props.state.amortization_table,
        customer: {
          name: props.state.name,
          phone: props.state.phone,
          email: props.state.email,
          document: props.state.document,
          birth_date: props.state.birth_date,
          whatsapp: true,
        },
        property: {
          cep: props.state.cep,
          complement: props.state.complement,
          neighborhood: props.state.neighborhood,
          property_type: props.state.property_type,
          price: props.state.property_value,
        },
      };

      const { customer, loan_amount } = data;

      await apiServiceTrello.post('/trello/card/new', {
        idList: '62670f3820002b40cbf376cb',
        name: customer.name,
        product_id: props.state.product_id,
        desc: `DATA: ${new Intl.DateTimeFormat('pt-BR', {
          timeZone: 'UTC',
        }).format(new Date())}\nNOME: ${customer.name}\nTELEFONE: ${
          customer.phone
        }\nE-EMAIL: ${
          customer.email
        }\nVALOR SOLICITADO: R$ ${loan_amount}\nCNPJ: ${customer.document}`,
      });

      await api.post('solicitations', data);

      //submit
      addToast({
        type: 'success',
        title: 'Enviado!!',
        description:
          'Sua simulação foi enviada para análise com sucesso e nossos consultores entraram em contato! ',
      });

      setTimeout(() => {
          history.push('/simulador/confirmation');
          window.location.reload();
      },1600)

      //Global site tag (gtag.js) - Google Ads: 385841188
      gtag('event', 'conversion', {
        send_to: 'AW-385841188/wi57COX24IwDEKTw_bcB',
        value: 1.0,
        currency: 'BRL',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao prosseguir',
        description: 'Por favor preencha todos os campos.',
      });
    }
  }, [props, addToast, history]);

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <div>
            <StepProgressBar steps={stepsArray} currentStep={3} />
          </div>

          <SolicitationData>
            <h3>Dados da simulação</h3>
            <ul>
              {props.state.loan_amount && (
                <li>
                  Valor do empréstimo:{' '}
                  <Info> R$ {props.state.loan_amount} </Info>
                </li>
              )}
              {props.state.property_value && (
                <li>
                  Valor do Imóvel:{' '}
                  <Info> $RS {props.state.property_value} </Info>
                </li>
              )}
              {props.state.total_installments && (
                <li>
                  Qtd. Parcelas: <Info> {props.state.total_installments} </Info>
                </li>
              )}
              {props.state.amortization_table && (
                <li>
                  Tabela de Amortização:{' '}
                  <Info> {props.state.amortization_table}</Info>
                </li>
              )}
            </ul>

            <h3>Seus dados</h3>
            <ul>
              <li>
                Nome: <Info>{props.state.name}</Info>
              </li>
              <li>
                E-mail: <Info>{props.state.email} </Info>
              </li>
              <li>
                CPF: <Info>{props.state.document}</Info>
              </li>
              <li>
                Data Nasc:{' '}
                <Info>
                  {new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                    new Date(props.state.birth_date),
                  )}
                </Info>
              </li>
              <li>
                Celular/Telefone: <Info>{props.state.phone}</Info>
              </li>
            </ul>

            {props.state.cep && <h3>Dados do imóvel</h3>}

            {props.state.cep && (
              <ul>
                <li>
                  CEP: <Info>{props.state.cep}</Info>
                </li>
                <li>
                  Endereço: <Info>{props.state.neighborhood}</Info>
                </li>
                <li>
                  Complemento: <Info>{props.state.complement}</Info>
                </li>
                <li>
                  Tipo: <Info>{props.state.property_type}</Info>
                </li>
              </ul>
            )}
          </SolicitationData>

          <FormGroup>
            <button className="back" onClick={props.prev}>
              <FiChevronLeft />
              Voltar
            </button>
            <Button onClick={handleSubmit}> Finalizar </Button>
          </FormGroup>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
};

export default CompletedSteps;
