import React, { useCallback, useRef, useState, useEffect } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import pgto from '../../utils/pgto';
import CountUp from 'react-countup';

import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Slider from '../../components/SliderComponent';
import StepProgressBar from '../StepProgressBar';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  InputGroup,
  SliderGroup,
  Label,
  SimulationSummary,
  SimulationSummaryMobile
} from './styles';

const domainConsignado = [36, 120];

const ConsignadoSimulator: React.FC = (props: any) => {

  const rate = 0.89; //0,85% A.M
  const [update, setUpdate] = useState<ReadonlyArray<number>>([props.getState('total_installments', 90)])
  const [values, setValues] = useState<ReadonlyArray<number>>([props.getState('total_installments', 90)])

  const [loanAmount, setLoanAmount] = useState(props.getState('loan_amount', ''));
  const [totalInstallments, setTotalInstallments] = useState(props.getState('total_installments', 90));

  const [firstInstallment, setFirstInstallment] = useState(0)

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  //Valor padrao da tabela de amortização e das parcelas
  useEffect(() => {

    //Product Id
    if (props.getState('product_id') === undefined) {
      props.setState('product_id', 2);
    }

    if (props.getState('total_installments') === undefined) {
      props.setState('total_installments', 90);
    }

  }, [props]);

  const onUpdate = (u: ReadonlyArray<number>) => {
    console.log(update);
    setUpdate(u);
  };

  const onChange = (v: ReadonlyArray<number>) => {
    props.setState('total_installments', v[0])
    setTotalInstallments(v[0]);
    setValues(v);
  };

  const next = async () => {

    try {

      const data = {
        loan_amount: loanAmount,
        total_installments: totalInstallments,
      }

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        loan_amount: Yup.string().required('valor do empréstimo é obrigatório'),
        total_installments: Yup.number().required('Quantidade de parcelas é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      props.next();

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        addToast({
          type: 'error',
          title: 'Erro ao prosseguir',
          description: 'Por favor preencha todos os campos.',
        });

        return;
      }
    }

  }


  const handleChangeFieldForm = useCallback(
    async () => {

      try {
        setFirstInstallment(pgto(loanAmount, totalInstallments, rate))
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro ao simular',
          description: 'Ocorreu um erro ao realizar a simulação, tente novamente.',
        });
      }
    },
    [
      setFirstInstallment,
      addToast,
      totalInstallments,
      loanAmount,
      rate
    ],
  );

  useEffect(() => {
    handleChangeFieldForm()
  }, [
    loanAmount,
    totalInstallments,
    handleChangeFieldForm
  ])

  return (
    <Container>
      <Content>

        <AnimationContainer>
          <div>
            <StepProgressBar
              steps={['Simulação', 'Meus Dados', 'Solicitação']}
              currentStep={0} />
          </div>

          <Form ref={formRef} onSubmit={() => { }}>
            <h1>Meu Empréstimo</h1>

            <InputGroup>
              <Label>
                Valor do empréstimo
                </Label>
              <Input
                name="loan_amount"
                icon={MdAttachMoney}
                type="text"
                placeholder="R$ 0,00"
                value={loanAmount}
                onChange={(e) => {
                  props.setState('loan_amount', e.target.value)
                  setLoanAmount(e.target.value)
                }}
              />
            </InputGroup>

            <SliderGroup>

              <Label>
                Em quantas parcelas?
              </Label>
              <Slider domain={domainConsignado} values={values} onChange={onChange} onUpdate={onUpdate} />

            </SliderGroup>

            <SimulationSummaryMobile>
              <h4>
                Valor da parcela
              </h4>
              <span>
                <CountUp
                  end={firstInstallment}
                  duration={2}
                  separator="."
                  decimals={2}
                  decimal=","
                  prefix="R$ "
                />
              </span>
              <p>
                Taxa de Juros: 0,89% A.M * <br />
                Parcelas: {totalInstallments} <br />

              </p>
              <p className="small">
                * A taxa de juros varia a partir de 0.89% ao mês, <br />
              dependendo da análise de crédito e do perfil de cada cliente. <br />
              Resaltando que o valor da parcela <br />
             deve comprometer no máximo até 35% da renda mensal
            </p>
            </SimulationSummaryMobile>

            <Button onClick={next}> Solicitar empréstimo</Button>
          </Form>

        </AnimationContainer>
      </Content>

      <Background>
        <SimulationSummary>
          <h4>
            Valor da parcela
          </h4>
          <span>
            <CountUp
              end={firstInstallment}
              duration={2}
              separator="."
              decimals={2}
              decimal=","
              prefix="R$ "
            />
          </span>
          <p>
            Taxa de Juros: 0,89% A.M * <br />
            Parcelas: {totalInstallments} <br />

          </p>
          <p className="small">
            * A taxa de juros varia a partir de 0.89% ao mês, <br />
          dependendo da análise de crédito e do perfil de cada cliente. <br />
            Resaltando que o valor da parcela <br />
             deve comprometer no máximo até 35% da renda mensal
          </p>
        </SimulationSummary>
      </Background>
    </Container>
  );
}

export default ConsignadoSimulator;
